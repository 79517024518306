import React from 'react';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
  </React.Fragment>
);

export default NotFoundPage;
